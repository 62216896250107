import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import Home from "../views/home.vue";
// import Mother from './views/mother.vue';
// import Course from './views/course.vue';
// import System from './views/system.vue';
// import About from './views/about.vue';

// vue项目自带路由
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "启智茗星-首页",
    },
  },
  {
    path: "/mother",
    name: "Mother",
    component: () => import("../views/mother.vue"),
    meta: {
      title: "启智茗星-宝妈案例",
    },
  },

  {
    path: "/course",
    name: "Course",
    component: () => import("../views/course.vue"),
    meta: {
      title: "启智茗星-课程理念",
    },
  },
  {
    path: "/system",
    name: "System",
    component: () => import("../views/system.vue"),
    meta: {
      title: "启智茗星-课程体系",
    },
  },
  {
    path: "/recruit",
    name: "Recruit",
    component: () => import("../views/recruit.vue"),
    meta: {
      title: "启智茗星-招聘",
    },
  },

  {
    path: "/about",
    name: "About",
    component: () => import("../views/about.vue"),
    meta: {
      title: "启智茗星-关于我们",
    },
  },
  {
    path: "/recruitList",
    name: "recruitList",
    component: () => import("../views/recruitList.vue"),
    meta: {
      title: "启智茗星-招聘",
    },
  },
];

const routers = [...routes];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes: [...routes],
});
router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title : '启智茗星';
  next();
});
export default router;
